<template>
    <Defaultlayout :userprofile="userprofile">
        <PageSlider></PageSlider>
        <section class="newsletter">
            <div id="icontactSignupFormWrapper331">
                <!-- <script type="text/javascript" async
                    src="https://app.icontact.com/icp/core/mycontacts/signup/designer/form/automatic?id=331&cid=590050&lid=18793&divid=icontactSignupFormWrapper331">

                </script> -->
                <iframe src="https://www.gharbazaar.com/newsletter.php" width="650px" height="650px"></iframe>
            </div>
            <p class="thank-you-newsletter">*Thank you for your interest in signing up for our newsletters. We will be
                sending our promotional offers to
                you at the email and/or phone number you enter in this form.</p>
        </section>
    </Defaultlayout>
</template>
<script>
import Defaultlayout from "../../layouts/Defaultlayout";
import PageSlider from "../pages/PageSlider";
export default {
    name: "Newsletter",
    components: {
        Defaultlayout,
        PageSlider,
    },
    data() {
        return {
            loading: true,
            userprofile: null,
        };
    },
    mounted() {
        if (localStorage.getItem("user") != null) {
            this.userprofile = JSON.parse(localStorage.getItem("user"));
        } else {
            this.userprofile = null;
        }
        // const script = document.createElement('script');
        // script.type = 'text/javascript';
        // script.async = true;
        // script.src = "https://app.icontact.com/icp/core/mycontacts/signup/designer/form/automatic?id=331&cid=590050&lid=18793&divid=icontactSignupFormWrapper331";
        // document.getElementById('icontactSignupFormWrapper331').appendChild(script);
    },
};
</script>
<style>
.thank-you-newsletter {
    max-width: 600px;
    margin: 0px auto;
    margin-top: 20px;
    font-size: 13px;
    text-align: justify;
}
</style>